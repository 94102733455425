import router from "./router";

const configurationNavItems = [
    {
        label: "Settings",
        icon: "mdi-cogs",
        to:"/payroll/settings",
        permissions: ['Global.Update']
    }
  ]

export default {
    router,
    configurationNavItems
}