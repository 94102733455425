import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useBackend } from '@/composables/backend'

const STORE_NAME = 'auth'
const baseUri = process.env.VUE_APP_API_URL

let isRefreshing = false

export const useAuthStore = defineStore(STORE_NAME, () => {
    
    
    const auth = ref(null)

    const lastModule = ref(null);

    function saveLastModule(moduleName) {
        lastModule.value = moduleName;
    }

    function getLastModule() {
        return lastModule.value;
    }

    const { item: currentUser, getItem } = useBackend('user')
    const { item: currentTenant, getItem: getTenant } = useBackend('tenant')

    const payRollIntegrationName = computed( () => {
        return currentTenant?.value?.integrations?.find(a => a.type === "PayrollSystem")?.name || "Payroll Provider"
    })

    const posIntegrationName = computed( () => {
        return currentTenant?.value?.integrations?.find(a => a.type === "PosSystem")?.name || "Point of Sale System"
    })

    const accountingIntegrationName = computed( () => {
        return currentTenant?.value?.integrations?.find(a => a.type === "AccountingSystem")?.name || "Accounting Software"
    })

    const permissions = computed(() => currentUser.value?.permissions || [])

    const hasPermission = (permission) => {
        return permissions.value.includes(permission)
    }

    const refreshTokenTimeout = ref(null)
    
    const isLoggedIn = computed(() => {
        return (auth.value !== null)
    })

    const isImpersonating = computed(() => {
        return auth.value?.isImpersonating ?? false;
    })

    async function login(username, password){
        try {
            const res = await fetch(`${baseUri}/user/login`, {
                method: 'POST',
                body: JSON.stringify({ username, password }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
    
            if (res.ok) {
                const data = await res.json()
                auth.value = {
                    ...data,
                    isImpersonating: false,
                    impersonatedTenantId: null
                }
                startRefreshTokenTimer()
                await getItem('current')
                await getTenant('current')
                return auth.value
            } else {
                throw Error('Incorrect login credentials')
            }
        } catch (er) {
            if (er.message === 'Incorrect login credentials') {
                throw Error('Incorrect username or password.')
            } else {
                throw Error('Failed to communicate with server. Please contact support.')
            }
        }
    }

    function logout(){
        auth.value = null
        stopRefreshTokenTimer()
        currentUser.value = null
    }

    async function impersonate(tenantId) {
        try {
            const res = await fetch(`${baseUri}/tenant/${tenantId}/impersonate`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${auth.value.accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }

            const data = await res.json();
            // Update auth.value with new accessToken and expiration
            auth.value = {
                ...data,
                isImpersonating: true,
                impersonatedTenantId: tenantId
            };

            stopRefreshTokenTimer();
            getItem('current');
            await getTenant('current')
            return auth.value;

        } catch (error) {
            console.error("Failed to impersonate:", error);
            throw error;
        }
    }

    async function revertImpersonation() {
        try {
            const res = await fetch(`${baseUri}/tenant/revertImpersonation`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${auth.value.accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }

            const data = await res.json();
            auth.value = {
                ...data,
                isImpersonating: false,
                impersonatedTenantId: null
            }
            startRefreshTokenTimer()
            await getItem('current')
            await getTenant('current')
            return auth.value

        } catch (error) {
            console.error("Failed to revert impersonation:", error);
            throw error;
        }
    }

    async function refreshToken(){
        if (isRefreshing) {
            console.log("Refresh already in progress.");
            return;
        }

        isRefreshing = true;

        try {
            if (auth.value.isImpersonating && !auth.value.refreshToken) {
                // Re-impersonate to get a new AccessToken
                // await impersonate(auth.value.impersonatedTenantId);
            } else {
                const res = await fetch(`${baseUri}/user/refresh`, {
                    method: 'POST',
                    body: JSON.stringify({ 
                        AccessToken: auth.value.accessToken, 
                        RefreshToken: auth.value.refreshToken 
                    }),
                    headers: { 'Content-Type': 'application/json' }
                });

                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }

                const data = await res.json();
                // Update auth.value with new tokens
                auth.value = {
                    ...auth.value,
                    accessToken: data.AccessToken,
                    refreshToken: data.RefreshToken,
                    expiration: data.Expiration
                };

                startRefreshTokenTimer();
                await getItem('current');
                await getTenant('current')
                return auth.value;
            }
        } catch (error) {
            console.log("Cannot communicate with server", error)
            auth.value = null
            stopRefreshTokenTimer()
            currentUser.value = null
        } finally {
            isRefreshing = false;
        }
    }

    function startRefreshTokenTimer() {
        const jwtBase64 = auth.value.accessToken.split('.')[1];
        const jwtToken = JSON.parse(atob(jwtBase64));

        const expires = new Date(jwtToken.exp * 1000);
        console.log('Token expires at:', expires);

        const timeout = expires.getTime() - Date.now() - (60 * 1000); // Refresh 1 minute before expiry

        if (timeout <= 0) {
            // Token is about to expire or already expired
            refreshToken();
        } else {
            refreshTokenTimeout.value = setTimeout(refreshToken, timeout);
        }
    }

    function stopRefreshTokenTimer(){
        clearTimeout(refreshTokenTimeout.value)
    }

    return { 
        auth, 
        currentUser, 
        currentTenant,
        payRollIntegrationName,
        posIntegrationName,
        accountingIntegrationName,
        isLoggedIn, 
        isImpersonating,
        login, 
        logout, 
        impersonate,
        revertImpersonation,
        refreshToken, 
        refreshTokenTimeout, 
        saveLastModule, 
        getLastModule,
        permissions,
        hasPermission
    }
}, { persist: true })