<template>
  <div class="nav-drawer-wrapper">
    <div class="nav-drawer-spacer"></div>
    <v-navigation-drawer class="custom-nav-drawer" :rail="!expanded" color="primary" permanent width="270">
      <div class="logo-container">
        <img :src="rLogo" alt="R" class="r-logo" :class="{ 'collapsed': !expanded }"/>
        <img :src="razayyaLogo" alt="Razayya" :class="{ 'collapsed': !expanded }" class="razayya-logo"/>
        <img :src="financialLogo" alt="Financial" :class="{ 'collapsed': !expanded }" class="financial-logo"/>
      </div>

      <v-spacer />

      <template v-if="featuredLinks?.length > 0">
        <v-list nav>
          <v-list-item
            v-for="item in featuredLinks"
            :key="item"
            :title="item.label"
            :to="item.to"
            @click="item.onClick"
          >
          <template v-slot:prepend>
              <v-icon v-if="item.icon" :icon="item.icon"></v-icon>
          </template>
      </v-list-item>
        </v-list>
        
      </template>

      <v-list density="compact" nav>
        <template v-for="item in currentModuleRoutes" :key="item.path">
          <v-list-group v-if="item.children" prepend-icon="item.meta.icon" :value="item.meta.expanded">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                :title="item.meta.breadcrumb?.label || item.name"
              >
              <template v-slot:prepend>
                  <v-icon v-if="item.meta.icon" :icon="item.meta.icon"></v-icon>
              </template>
              </v-list-item>
            </template>
            <v-list-item
              v-for="child in item.children"
              :key="child.path"
              :to="child.path"
              :class="!expanded ? 'child-list-item-rail' : ''"
              :title="child.meta.breadcrumb?.label || child.name"
            >
              <template v-slot:prepend>
                  <v-icon v-if="child.meta.icon" :icon="child.meta.icon"></v-icon>
              </template>
              </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :to="item.fullPath"
            :title="item.meta.breadcrumb?.label || item.name"
          >
              <template v-slot:prepend>
                  <v-icon v-if="item.meta.icon" :icon="item.meta.icon"></v-icon>
              </template>
          </v-list-item>
        </template>
      </v-list>

      <template v-slot:append>
        <v-list nav density="compact">
          <v-list-item
            v-for="item in configurationLinks"
            :key="item"
            :title="item.label"
            :prepend-icon="item.icon"
            :to="item.to"
            @click="item.onClick"
          />
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script setup>
  import rLogo from '@/assets/wg-r-logo.svg';
  import razayyaLogo from '@/assets/gold-razayya-logo.svg';
  import financialLogo from '@/assets/white-financial-logo.svg';

  import { getModuleRoutes, getConfigurationNavItems, getFeatureNavItems } from '@/registerModules';
  import { ref, watch, computed } from 'vue';
  import { useModuleStore } from '@/stores/moduleStore';
  import { useAuthStore } from '@/stores'

  const props = defineProps({ expanded: Boolean });

  const authStore = useAuthStore()
  const { hasPermission } = authStore

  const { getCurrentModule } = useModuleStore();
  const currentViewBox = ref('0 100 576 350');

watch(
  () => props.expanded,
  (newVal) => {
    if (!newVal) {
      setTimeout(() => {
        currentViewBox.value = '180 115 200 200';
      }, 150); // Adjust the delay as needed
    } else {
      currentViewBox.value = '0 100 576 350';
    }
  }
);

  const moduleRoutes = getModuleRoutes();

  // Compute the full path for each route within the current module
  const currentModuleRoutes = computed(() => {
    const currentModule = getCurrentModule();
    return moduleRoutes
      .filter((modRoute) =>  modRoute.meta?.module === currentModule)
      .flatMap((modRoute) =>
        modRoute.children
          .filter((route) => route.meta?.showInNav)
          .filter( route => {
            if (route.meta.permissions && route.meta.permissions.length > 0) {
              return route.meta.permissions.some(permission => hasPermission(permission))
            }
            return true
          })
          .map((route) => ({
            ...route,
            fullPath: route.path.startsWith('/') ? route.path : `${modRoute.path}/${route.path}` // Compute the full path
          }))
      );
  });


// Filter configuration links based on permissions
const configurationLinks = computed(() => {
  // Get current module name
  const currentModuleName = getCurrentModule();

  // Get configuration navigation items for the current module
  const rawConfigurationLinks = getConfigurationNavItems(currentModuleName);
  
  return rawConfigurationLinks.filter((item) => {
    if (item.permissions && item.permissions.length > 0) {
      return item.permissions.some((permission) => hasPermission(permission));
    }
    // If no permissions specified, include the item
    return true;
  });
});

// Similarly, filter featuredLinks if necessary
const featuredLinks = computed(() => {
  const currentModuleName = getCurrentModule();
  const rawFeaturedLinks = getFeatureNavItems(currentModuleName);
  return rawFeaturedLinks.filter((item) => {
    if (item.permissions && item.permissions.length > 0) {
      return item.permissions.some((permission) => hasPermission(permission));
    }
    return true;
  });
});
</script>


<style>
.logo-svg {
  width: 100%;
  height: auto;
  transition: viewBox 1s ease;
}

/* Custom Navigation Drawer Style */
.nav-drawer-wrapper {
  display: flex;
}

.nav-drawer-spacer {
  width: 6px; /* Adjust the width as needed */
  /* background-color: #FFC655; /* secondary color from Vuetify theme */
  background-color: #FFC655; /* primary color from Vuetify theme */
  left: 0px;
  z-index: 1008;
  transform: translateX(0%);
  position: fixed;
  height: calc(100% + 0px);
  top: 0px;
  bottom: 0px;
}

.custom-nav-drawer {
  position: relative;
  left: 6px !important;
}

  .v-navigation-drawer--rail .v-list-group__items .v-list-item {
      padding-inline-start: 8px !important;
  }

  .logo-container {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.r-logo {
  width: 4vh;
  max-width: 4vh;
  min-width: 4vh;
  transition: all 0.3s ease;
}

.r-logo.collapsed {
  width: 4vh;
  min-width: 3vh;
  transition: all 0.3s ease;
}

.razayya-logo, .financial-logo {
  width: 16vh;
  max-width: 16vh;
  min-width: 16vh;
  opacity: 1;
  padding: 3px 0;
  transition: all 0.3s ease;
}

.razayya-logo.collapsed, .financial-logo.collapsed {
  width: 0vh;
  max-width: 0px;
  min-width: 0px;
  opacity: 0;
  padding: 0;
}
</style>