import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HelpView from '../views/HelpView.vue'
import NotFound from '@/views/NotFoundView.vue'
import { useAuthStore } from '@/stores'
import { useModuleStore } from '@/stores/moduleStore'
import { useUtil } from '@/composables'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: true,
      title: 'Razayya Financial | Insights'
    },
    component: HomeView
  },
  {
    path: '/help',
    name: 'help',
    meta: {
      requiresAuth: true,
      title: 'Razayya Financial | Insights | Help'
    },
    component: HelpView
  },
  {
    path: '/support',
    name: 'support',
    meta: {
      requiresAuth: true,
      title: 'Razayya Financial | Insights | Support'
    },
    component: HelpView
  },
  {
    path:'/my-account',
    name:'myAccount',
    meta: {
      requiresAuth: true,
      title: 'Razayya Financial | Insights | My Account',
      breadcrumb: {
        label: 'My Account',
      },
    },
    component: () => import( '@/views/MyAccountView.vue' )
  },
  {
    path: '/not-authorized',
    name: 'notAuthorized',
    component: NotFound
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const { isLoggedIn, auth, currentUser, saveLastModule, refreshToken, hasPermission } = useAuthStore()
  const { setCurrentModule, getCurrentModule } = useModuleStore()
  const { parseJwt } = useUtil()

  if( isLoggedIn && currentUser == null ){
    await refreshToken()
  }

  // Set document title based on route meta
  document.title = to.meta.title || 'Razayya Financial | Insights'

  // Handle token expiration and refresh
  if (isLoggedIn && auth.accessToken) {
    const jwtPayload = parseJwt(auth.accessToken)

    if (jwtPayload.exp < Date.now() / 1000) {
      await refreshToken()
    }
  }

  // Save the last visited module path
  saveLastModule(to.fullPath)

  // Check for required authentication
  if (to.meta.requiresAuth && !isLoggedIn) {
    // Redirect to login page with the intended route as query parameter
    return next({
      path: '/auth/login',
      query: { redirect: to.fullPath }
    })
  }

  // Check for permissions
  if (to.meta.permissions && to.meta.permissions.length > 0) {
    console.log(to.meta.permissions)
    const hasAnyPermission = to.meta.permissions.some(permission => hasPermission(permission))
    if (!hasAnyPermission) {
      return next('/not-authorized') // Redirect or show an error page
    }
  }

  if( to.meta?.module && to.meta.module !== getCurrentModule() ){
    setCurrentModule( to.meta.module)
  }

  // Continue to the route
  next()
})

export default router
