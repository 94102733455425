import { createApp } from 'vue'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueCsvImportPlugin from './plugins/vueCsvImportPlugin'

import authModule from './modules/authModule'
import reconcilerModule from './modules/reconcilerModule'
import payrollModule from './modules/payrollModule'
import analyticsModule from './modules/analyticsModule'
import revenueModule from './modules/revenueModule'
import financialModelingModule from './modules/financialModelingModule'

import { registerModules } from './registerModules'
import adminModule from './modules/adminModule'

loadFonts()

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

registerModules({
    auth: authModule,
    reconciler: reconcilerModule,
    payroll: payrollModule,
    admin: adminModule,
    analytics: analyticsModule,
    revenue: revenueModule,
    modeling: financialModelingModule
})

createApp(App)
  .use(pinia)
  .use(router)
  .use(vuetify)
  .use(VueCsvImportPlugin)
  .mount('#app')