<template>
  <div class="text-center">
    <v-btn v-if="!authStore.isLoggedIn" @click="router.push('/auth/login')">Login</v-btn>
    <div v-if="authStore.isLoggedIn" class="user-info">
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-avatar color="primary" v-bind="props" size="32">
            <span>{{ authStore?.currentUser?.firstName?.charAt(0) ?? '' }}</span>
          </v-avatar>
        </template>
        <v-list>
          <v-list-item title="My Account" to="/my-account" />
          <v-list-item @click="restoreTenant" v-if="authStore.isImpersonating" title="Return to Admin Tenant" />
          <v-divider />
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores'
import { ref } from 'vue'

const router = useRouter()
const authStore = useAuthStore()

const restoreTenant = async () => {
  await authStore.revertImpersonation()
  router.push(`/admin/tenants`)
}

const logout = () => {
  authStore.logout()
  router.push('/auth/login')
}
</script>

<style>
.user-info {
  display: flex;
  align-items: center;
}
</style>
